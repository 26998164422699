.App {
    /* text-align: center; */
    /* max-width: 100%; */
  }
  
  .App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  nav {
    text-align: center;
    padding: 5px;
  }
  
  nav ul {
    display: inline-block;
  }
  
  nav li {
    float: left;
    list-style: none;
    margin-right: 5px;
  }
  
  
  
  
  .App {
    font-family: sans-serif;
    /* text-align: center; */
  }
  
  
  /*
   * React Data Grid
   * Default grid borders and selection highlight
   */
  .react-grid-HeaderCell,
  .react-grid-Cell {
      box-sizing: border-box;
  }
  .rdg-selected {
    box-sizing: border-box;
  }
  
  .react-contextmenu {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease !important;
  }
  
  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
  }
  
  .react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #373a3c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
  }
  
  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
  }
  
  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    color: #878a8c;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }
  
  .react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
  }
  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }
  
  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }
  
  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
  }
  
  .react-contextmenu-item.react-contextmenu-submenu
    > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
  }
  
  .example-multiple-targets::after {
    content: attr(data-count);
    display: block;
  }
  
  .react-grid-Cell__value {
    white-space: normal !important;
    font-size: 0.7em;
    overflow: scroll !important;
    margin: 3px 0;
    /* margin-top: 10px; */
  }
  
  /* .react-grid-Grid {
      min-height: calc(100vh - 56px) !important;
  } */
  
  /*
  .react-grid-Main{
  height: 100%;
  }
  
  .react-grid-Container{
  height: 100%;
  }
  
  .react-grid-Grid{
  min-height: 100% !important;
  }
  
  .react-grid-Canvas{
  height: 100% !important;
  } */
  